import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 1.5a7.5 7.5 0 100 15 7.5 7.5 0 000-15z"
        fill="#000"
        stroke="#000"
        strokeWidth={1.125}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 8.893l1.875 1.875 4.018-4.018"
        stroke="#fff"
        strokeWidth={1.125}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgComponent
