import config from './config'
import debounce from './debounce'

const checkEmailDebounced = debounce((value, res, rej, cbError) => {
  fetch(`${config.backendUri}/auth/check-email?e=${value}`)
    .then(response => {
      if (!response.ok) {
        cbError('No successful response from server')
        res(true)
      }

      if (response.status === 500) {
        cbError('Sorry, our server was unable to perform request')
        res(true)
      }

      return response.json()
    })
    .then(({ isValid }) => {
      if (isValid) {
        res(true)
      } else {
        res(false)
      }
    })
    .catch(() => {
      cbError('An error occured while performing request')
      res(true)
    })
}, 500)

export default checkEmailDebounced
