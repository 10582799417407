import moment from 'moment'

function getNextProductTourDate(startDate) {
  const start = moment(startDate)
  const diffInDays = moment().diff(start, 'days')

  if (diffInDays <= 0) {
    return start
  }
  const daysLeftNextSched = Math.ceil(diffInDays / 14)
  const nextSched = start.add(daysLeftNextSched * 14, 'days')
  return nextSched
}

export default getNextProductTourDate
