import React, { useState } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby-link'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'

import Nav from '../components/nav'
import Footer from '../components/footer'
import Button from '../components/button'
import Box from '../components/box'
import SEO from '../components/seo'
import Helper from '../components/signupHelper'
import Loader from '../components/loader'

import CalenderSvg from '../icons/calendar'
import CheckSvg from '../icons/listCheck'
import getNextProductTourDate from '../utils/getNextProductTourDate'
import checkEmailDebounced from '../utils/checkEmailDebounced'
import config from '../utils/config'
import useHotjarScript from '../utils/hooks/useHotjarScript'

// 8:30 A.M PST Tuesday (May 11)
const TOUR_START_DATE = Date.UTC(2021, 3, 20, 15, 30)
// After 1 hour
const TOUR_END_DATE = Date.UTC(2021, 3, 20, 16, 30)

const ProductTour = () => {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState(null)

  function submitToServer(body) {
    const productTourApi = `${config.backendUri}/services/website/product-tour/request`
    return new Promise((resolve, reject) => {
      fetch(productTourApi, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
        .then(response =>
          response.status === 200
            ? resolve(true)
            : reject('Error submitting data')
        )
        .catch(reject)
    })
  }

  const handleSubmit = async values => {
    setIsSubmitting(true)
    try {
      await submitToServer(values)
      setIsSubmitSuccess(true)
      setSubmitError(null)
    } catch (e) {
      setSubmitError('An error occured while submitting your request')
      setIsSubmitSuccess(false)
    }
    setIsSubmitting(false)
  }

  useHotjarScript()

  return (
    <Container>
      <Nav theme="light" />
      <SEO
        title="Product Tour - Pulse"
        description="Join our team twice a month every Thursday for a live product tour of Pulse. Learn how Pulse can help you stay connected and aligned more effectively than emails and slack."
      />
      <Main>
        {isSubmitSuccess ? (
          <SubmitSuccess />
        ) : (
          <SubmitForm
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            submitError={submitError}
          />
        )}

        <CreateWorkspaceBanner>
          <H3>Aren’t yet on Pulse?</H3>
          <CopyBody>
            Keep your people more connected and aligned. Create your Pulse
            workspace in {`<`}2min.
          </CopyBody>
          <Box marginTop="1.5rem" marginBottom="1rem">
            <StyledButton onClick={() => navigate('/signup')}>
              Create You Workspace
            </StyledButton>
          </Box>
          <span id="support-contact">
            Need help?
            <a
              href="mailto:support@pulse.so"
              style={{ textDecoration: 'none' }}
            >
              support@pulse.so
            </a>
          </span>
        </CreateWorkspaceBanner>
      </Main>

      <Footer />
    </Container>
  )
}

const SignupSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(1, 'Full name is required')
    .required('Full name is required'),
  email: Yup.string()
    .email('Please input valid email')
    .test('Check valid email', 'Must be a valid company email', function (
      value
    ) {
      return new Promise((res, rej) => {
        checkEmailDebounced(value, res, rej, error => console.log('err', error))
      })
    })
    .required('Email is required'),
  role: Yup.string().min(1, 'Role is required').required('Role is required'),
  department: Yup.string()
    .min(1, 'Department is required')
    .required('Department is required'),
})

const SubmitForm = ({ handleSubmit, isSubmitting, submitError }) => {
  return (
    <>
      <Emoji>
        <span role="img" aria-label="wave-emojiy">
          👋🏽
        </span>
      </Emoji>
      <H2>Pulse product tour</H2>
      <Chip>
        <span id="label">Next event</span>
        <div id="date">
          <CalenderSvg />
          <span>
            {getNextProductTourDate(TOUR_START_DATE).format(
              'MMM Do, YYYY hh:mm a'
            )}
          </span>
        </div>
      </Chip>
      <CopyBody style={{ marginBottom: '1.875rem' }}>
        Join the Pulse team twice a month every Thursday for a live product
        tour. Learn how to master asynchronous communication to increase your
        team communication bandwidth, eliminate aimless meetings, boost
        productivity, and keep everyone on the same page, all the time.
      </CopyBody>
      <Formik
        initialValues={{
          fullName: '',
          email: '',
          role: '',
          department: '',
        }}
        validationSchema={SignupSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          errors,
        }) => (
          <>
            <StyledForm>
              <div className="row">
                <label className="form-label" for="fullName">
                  Full name
                  <input
                    className="form-input"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="fullName"
                    value={values.fullName}
                  />
                  <Helper error={touched.fullName && errors.fullName}>
                    {touched.fullName && errors.fullName && errors.fullName}
                  </Helper>
                </label>
              </div>
              <div className="row">
                <label className="form-label" for="work-email">
                  Work email
                  <input
                    className="form-input"
                    onChange={handleChange}
                    name="email"
                    value={values.email}
                    onBlur={handleBlur}
                  />
                  <Helper error={touched.email && errors.email}>
                    {touched.email && errors.email && errors.email}
                  </Helper>
                </label>
              </div>
              <div className="row">
                <label className="form-label" for="role">
                  Role
                  <input
                    className="form-input"
                    name="role"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.role}
                  />
                  <Helper error={touched.role && errors.role}>
                    {touched.role && errors.role && errors.role}
                  </Helper>
                </label>
              </div>
              <div className="row">
                <label className="form-label" for="department">
                  Department
                  <select
                    className="form-input form-select"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="department"
                    value={values.department}
                    style={{ color: values.department ? '#000' : '#CBCBCB' }}
                  >
                    <option disabled selected value="">
                      Select one...
                    </option>
                    <option value="Engineering">Engineering</option>
                    <option value="Leadership">Leadership</option>
                    <option value="Marketing">Marketing</option>
                    <option value="Customer Success">Customer Success</option>
                    <option value="Customer Support">Customer Support</option>
                    <option value="Operations">Operations</option>
                    <option value="HR">HR</option>
                    <option value="Product">Product</option>
                    <option value="Design">Design</option>
                    <option value="Sales">Sales</option>
                    <option value="Other">Other</option>
                  </select>
                  <Helper error={touched.department && errors.department}>
                    {touched.department &&
                      errors.department &&
                      errors.department}
                  </Helper>
                </label>
              </div>

              {submitError && (
                <Helper error={submitError}>{submitError}</Helper>
              )}
              <Button
                type="submit"
                className="form-button"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                Get Invitation
                {isSubmitting && <Loader style={{ marginLeft: '.5rem' }} />}
              </Button>
            </StyledForm>
          </>
        )}
      </Formik>
    </>
  )
}

const SubmitSuccess = () => {
  const addToCalendar = () => {
    const text = `Pulse Product Tour`
    const description = `Join the Pulse team twice a month every Thursday for a live product tour. Learn how to master asynchronous communication to increase your team communication bandwidth, eliminate aimless meetings, boost productivity, and keep everyone on the same page, all the time.`
    const dates = encodeURIComponent(
      `${moment
        .utc(getNextProductTourDate(TOUR_START_DATE))
        .format('YYYYMMDDTHHmmss')}Z/${moment
        .utc(getNextProductTourDate(TOUR_END_DATE))
        .format('YYYYMMDDTHHmmss')}Z`
    )
    window.open(
      `https://calendar.google.com/calendar/r/eventedit?text=${text}&details=${description}&dates=${dates}`
    )
  }

  return (
    <>
      <Emoji>
        <span role="img" aria-label="wave-emojiy">
          ✌🏽
        </span>
      </Emoji>
      <H2 style={{ textAlign: 'center' }}>
        You just snagged your invitation to the Pulse product tour. See you next
        Thursday
      </H2>
      <Ul>
        <Li>
          <CheckSvg />
          See Pulse in action and learn how you can use it internally
        </Li>
        <Li>
          <CheckSvg />
          Explore ideas and best practices about remote alignment
        </Li>
        <Li>
          <CheckSvg />
          Learn how others are using Pulse to stay aligned at scale
        </Li>
        <Li>
          <CheckSvg />
          If you’ll miss the date we’ll email you the recording
        </Li>
        <Li>
          <CheckSvg />
          Hop in and ask our team questions or doubts
        </Li>
      </Ul>
      <Box style={{ width: '23.5rem', marginTop: '0.75rem', maxWidth: '90vw' }}>
        <StyledButton onClick={addToCalendar}>Add To Calendar</StyledButton>
      </Box>
    </>
  )
}

const Container = styled.div`
  width: 100vw;
`

const Main = styled.main`
  width: 37.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  max-width: 90vw;
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
`

const Emoji = styled.span`
  font-weight: 900;
  font-size: 2.5rem;
  line-height: 3.5rem;
  margin: 0;
  margin-bottom: 1.5rem;
`

const H2 = styled.h2`
  font-family: ${props => props.theme.typography.fontFamilyGTBold};
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin: 0;
  margin-bottom: 1.5rem;
`

const H3 = styled.h3`
  font-family: ${props => props.theme.typography.fontFamilyGT};
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #000000;
`

const Chip = styled.div`
  padding: 0.375rem 1rem;
  width: max-content;
  height: 2.125rem;
  border: 1px solid ${props => props.theme.core.border.opaque};
  box-sizing: border-box;
  border-radius: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  #label {
    font-family: ${props => props.theme.typography.fontFamilyMono};
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: ${props => props.theme.core.content.secondary};
    margin-right: 1rem;
  }

  #date {
    display: flex;
    align-items: center;
    font-family: ${props => props.theme.typography.fontFamilyGTBold};
    font-style: normal;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1.375rem;

    svg {
      margin-right: 0.5rem;
    }
  }
`

const CopyBody = styled.p`
  font-family: ${props => props.theme.typography.fontFamilyInter};
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: ${props => props.theme.core.content.tertiary};
  text-align: center;
`

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 23.5rem;
  max-width: 100%;
  margin: auto;

  label {
    display: flex;
    flex-direction: column;
  }

  input,
  select {
    margin-top: 0.5rem;
  }

  .row {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
    display: flex;
    flex-direction: column;
  }

  .form-label {
    font-family: ${props => props.theme.typography.fontFamilyGTMono};
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: ${props => props.theme.core.content.secondary};
    margin-bottom: 0.5rem;
  }

  .form-input {
    background: ${props => props.theme.colors.gray[50]};
    padding: 0.75rem 1rem;
    box-sizing: border-box;
    height: 3rem;
    border: 2px solid transparent;
    outline: none;
    font-family: ${props => props.theme.typography.fontFamilyGTMono};
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;

    :focus {
      border: 2px solid black;
    }
  }

  .form-select {
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-left: 1rem;
    padding-right: 1.875rem;
    position: relative;
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 4.67861L4.5 1L1 4.67861M1 9.32139L4.5 13L8 9.32139' stroke='%23CBCBCB' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
      no-repeat;
    background-position: right 0.3125rem top 50%;
  }

  .form-button {
    background: #000000;
    margin: 0.75rem 0rem;
    font-family: ${props => props.theme.typography.fontFamilyGT};
    font-style: normal;
    font-weight: 900;
    font-size: 0.875rem;
    line-height: 1rem;
    text-transform: capitalize;
    color: ${props => props.theme.core.content.inversePrimary};
    padding: 1rem;
    border: none;
    cursor: pointer;
    width: 100%;
  }
`

const StyledButton = styled(Button)`
  background: #000000;
  margin: 0.75rem 0rem;
  font-family: ${props => props.theme.typography.fontFamilyGT};
  font-style: normal;
  font-weight: 900;
  font-size: 0.875rem;
  line-height: 1rem;
  text-transform: capitalize;
  color: ${props => props.theme.core.content.inversePrimary};
  padding: 1rem;
  border: none;
  cursor: pointer;
  width: 100%;
`

const Ul = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 0.75rem;
`

const Li = styled.li`
  margin-bottom: 1rem;
  font-family: ${props => props.theme.typography.fontFamilyInter};
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: ${props => props.theme.core.content.tertiary};
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.75rem;
  }
`

const CreateWorkspaceBanner = styled.div`
  padding: 2.5rem 2.5rem;
  box-sizing: border-box;
  background: #f6f6f6;
  border: 0.125rem dashed #eeeeee;
  border-radius: 0.625rem;
  width: 100%;
  margin-top: 4rem;
  text-align: center;

  @media ${props => props.theme.device.tablet} {
    padding: 2.5rem 6.125rem;
  }

  #support-contact {
    font-family: ${props => props.theme.typography.fontFamilyGTMono};
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.75rem;
    color: rgba(0, 0, 0, 0.5);

    a {
      margin-left: 0.5rem;
      color: black;
      text-decoration: underline !important;
    }
  }
`

export default ProductTour
